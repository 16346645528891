
<template>
  <div class="page question-page">
    <QHeader />

    <img src="../../assets/images/q3.png" class="content" />

    <div class="ask">
      <div class="ask-no">
        <h4>第三题</h4>
        <p>QUESTION THIRD</p>
      </div>
      <div class="ask-line"></div>
      <div class="ask-content">
        <p>有一束气球出现在您面前，</p>
        <p>您拿了一个递给爱人</p>
      </div>
    </div>

    <div class="items">
      <div
        class="item"
        :class="{ active: key === choice }"
        v-for="(item, key) in items"
        :key="key"
        @click="select(key)"
      >
        {{ item }}
      </div>
    </div>

    <div class="action">
      <button class="btn" @click="back">上一题</button>
      <button class="btn next" @click="next">下一题</button>
    </div>
  </div>
</template>
<script>
import QHeader from "../../components/QHeader";
import { mapState } from "vuex";

export default {
  name: "Step3",

  components: { QHeader },

  computed: {
    ...mapState({
      record: (state) => state.detail.record,
    }),
  },

  data() {
    return {
      choice: null,

      items: ["A. 黄色", "B. 红色", "C. 蓝色", "D. 白色"],
    };
  },

  watch: {
    "$store.state.detail.record"(record) {
      this.choice = record[2];
    },
  },

  mounted() {
    this.choice = this.record[2];
    this.$store.dispatch("share/config");
  },

  methods: {
    select(key) {
      this.choice = key;
    },

    back() {
      this.$router.back();
    },

    next() {
      if (![0, 1, 2, 3].includes(this.choice)) {
        this.$layer.msg("请点击您的选择");
        return;
      }

      this.$store.commit("record/choice", [2, this.choice]);
      this.$router.push("/step4");
    },
  },
};
</script>
<style scoped lang="less">
img {
  display: block;

  &.content {
    width: 100%;
  }
}

.ask {
  margin: 40px 0;
}

.items {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    margin: 0 5px;
    padding: 25px 0 0 0;
    text-align: center;
    width: 70px;
    height: 150px;
    border: none;
    background: no-repeat center / contain;
    color: #000;
    position: relative;

    &:nth-child(1) {
      background-image: url("../../assets/images/b1.png");
    }

    &:nth-child(2) {
      background-image: url("../../assets/images/b2.png");
    }

    &:nth-child(3) {
      background-image: url("../../assets/images/b3.png");
    }

    &:nth-child(4) {
      background-image: url("../../assets/images/b4.png");
    }

    &.active {
      &::after {
        width: 30px;
        height: 20px;
        background: url("../../assets/images/tick.png") no-repeat center / cover;
        display: block;
        position: absolute;
        content: "";
        top: 45px;
        left: 20px;
      }
    }
  }
}
</style>